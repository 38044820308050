"use strict";
import Swiper from 'swiper';
import CollectionType from '@Lisa/assets/js/backend/Lisa/CollectionType.js';
import lazy from 'jquery-lazy';

// document ready
$(() => {
    $('.lazy').lazy();

    let collectionType = new CollectionType();
    // slider homepage veiling
    var swiper = new Swiper('.swiper-veiling', {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        // init: false,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        loop: false,
        grabCursor: true,
        breakpoints: {
            1200: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 30,
            }
        }
    });
    // slider homepage aanbod
    var swiper = new Swiper('.swiper-aanbod', {
        slidesPerView: 3,
        spaceBetween: 30,
        //autoplay: {
        //    delay: 2500,
        //    disableOnInteraction: false,
        // },
        // init: false,
        pagination: {
            el: '.swiper-pagination-2',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next-2',
            prevEl: '.swiper-button-prev-2',
        },
        loop: false,
        grabCursor: true,
        breakpoints: {
            1200: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 30,
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 30,
            }
        }
    });
    // detail slider
    $('.multiple-items').slick({
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        prevArrow: '<i class="fas fa-chevron-left"></i>',
        nextArrow: '<i class="fas fa-chevron-right"></i>',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });
    $('.multiple-items').slickLightbox({
        itemSelector: 'a',
        navigateByKeyboard: true
    });
    // stickymenu
    $(function () {
        //caches a jQuery object containing the header element
        var header = $(".header");
        $(window).scroll(function fixscroll() {
            var scroll = $(window).scrollTop();
            if (scroll >= 60) {
                header.removeClass('high').addClass("narrow");
            } else {
                header.removeClass("narrow").addClass('high');
            }
        });
        var scroll = $(window).scrollTop();
        if (scroll >= 60) {
            header.removeClass('high').addClass("narrow");
        } else {
            header.removeClass("narrow").addClass('high');
        }
    });
    // Plate
    function isMobile() {
        $('.usp-over').plate({
            perspective: 1000,
            maxRotation: 20,
            animationDuration: 100
        });
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    if (!isMobile()) {
    }

    // Auto-submit object filter
    $(document).on('change', 'select[id=unit_city_filter_city]', function (e) {
        $(this).closest('form').submit();
    });

    /**
     * Additional With Reservation
     */
    function withReservationFinanceToggle(e)
    {
    }

    $(document).on('change', 'input[name="bidding[withReservationFinance]"], input[name="blind_bidding[withReservationFinance]"]', function (e) {
        let $element = $(this);
        let checked = $element.val() == 1;
        let $target = $('.finance');
        let $inputs = $target.find('input');
        if (checked) {
            $target.removeClass('d-none');
            $inputs.attr('required', 'required');
        } else {
            $target.addClass('d-none');
            $inputs.removeAttr('required');
        }
    });

    $(document).on('change', 'input[name="bidding[withReservationSaleOwnHouse]"], input[name="blind_bidding[withReservationSaleOwnHouse]"]', function (e) {
        let $element = $(this);
        let checked = $element.val() == 1;
        let $target = $('.sale-own-house');
        let $inputs = $target.find('input');
        if (checked) {
            $target.removeClass('d-none');
            $inputs.attr('required', 'required');
        } else {
            $target.addClass('d-none');
            $inputs.removeAttr('required');
        }
    });

    $(document).on('change', 'input[name="bidding[withReservationNMG]"], input[name="blind_bidding[withReservationNMG]"]', function (e) {
        let $element = $(this);
        let checked = $element.val() == 1;
        let $target = $('.nmg');
        if (checked) {
            $target.removeClass('d-none');
        } else {
            $target.addClass('d-none');
        }
    });

    $(document).on('change', 'input[name="bidding[withReservationAdditional]"], input[name="blind_bidding[withReservationAdditional]"]', function (e) {
        let $element = $(this);
        let checked = $element.val() == 1;
        let $target = $('input[name*=withReservationExplanation], form[name=bidding] .add, form[name=blind_bidding] .add');
        let $inputs = $target.find('input');
        if (checked) {
            $target.removeClass('d-none');
            $inputs.attr('required', 'required');
            $target.trigger("click");
        } else {
            $target.addClass('d-none');
            $inputs.removeAttr('required');
        }
    });

    $(document).on('click', 'form[name=bidding] .add, form[name=blind_bidding] .add', function (e) {
        let $collectionContainer = $(this).closest('form').find('.collection-container');
        let $prototype = $collectionContainer.data('prototype');
        let $newEntry = $($prototype.replace(/__name__/g, $collectionContainer.children().length));
        $collectionContainer.append($newEntry);
        $newEntry.removeClass('d-none');
        $newEntry.focus();
        return false;
    });

    $(document).on('blur', 'input[name*=withReservationExplanation]', function (e) {
        let $element = $(this);
        if ($element.val().length > 0) {
            return;
        }
        let $collectionContainer = $element.parent();
        let children = Array.prototype.slice.call($collectionContainer.get(0).children);
        let index = children.indexOf(this);
        if (index > 0) {
            $element.remove();
        }
    });

    /**
     * Bidding form confirmation.
     */
    $(document).on('keypress', 'form[name="bidding"], form[name="blind_bidding"]', function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $('html, body').animate({scrollTop: $('.bidding-scroll-to').offset().top - 90}, 500);
        }
    });

    $(document).on('submit', 'form[name="bidding"], form[name="blind_bidding"]', function (e) {
        if (confirm(`Weet u zeker dat u dit bod wilt uitbrengen?`)) {
            return true;
        }
        return false;
    });

    //close popup
    $(".video-popup_close").click(function() {
      $(".video-popup").removeClass("active");
      $(".video-popup").addClass("active");
    });

    // date picker
    $('#bidding_transferDate, #blind_bidding_transferDate').datepicker({
        format: 'dd-mm-yyyy',
        language: 'nl-NL',
        autoHide: true,
    });

    $('#bidding_withReservationSaleOwnHouseDate, #blind_bidding_withReservationSaleOwnHouseDate').datepicker({
        format: 'dd-mm-yyyy',
        language: 'nl-NL',
        autoHide: true,
    });
});