/*
 * Goednieuws app's main JavaScript file!
 *
 * Recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 *
 * Webpack locks for all import and require statements.
 * Each js file will be put into the final built app.js file for (s)css
 * it puts those contents into the final build app.(s)css
 *
 * Support for legacy Jquery Code, use line below
 * => global.$ = $
 *
 * Use Yarn for package management. Packages could be found at: https://yarnpkg.com/lang/en/
 *
 * Container
 * If two files import the same module, they get back the same
 * one instance of it - a lot like Symfony's container
 */
import '../scss/app.scss';

import 'bootstrap';
import '@chenfengyuan/datepicker';

import '@visualmedia/cookie-notice';
import '@Menu/assets/js/Menu.js';

import 'slick-carousel';
import 'slick-lightbox';
import 'jquery-countdown';
import 'lity';

import './plugins/toggle';
import './plugins/jquery.plate';
import './plugins/countdown';

import './app/ajax';
import './app/phone-uri';
import './app/main';
