"use strict";

import { $, EventListener } from '@visualmedia/utils';

/**
 * Collection Type
 *
 * @author Vincent van Waasbergen <v.vanwaasbergen@visualmedia.nl>
 */
export default class CollectionType
{
    /**
     * Constructor
     */
    constructor()
    {
        this.addButton = '.collection-type-add';
        this.removeButton = '.collection-type-remove';

        this.setupListeners();
    }

    /**
     * Setup Listeners
     */
    setupListeners()
    {
        $(document).on('click', this.addButton, EventListener(this, 'add'));
        $(document).on('click', this.removeButton, EventListener(this, 'remove'));
    }

    /**
     * Add
     */
    add(e, $element)
    {
        e.preventDefault();

        this.$collectiontype = $element.closest('.collection-type');
        this.$container = this.$collectiontype.find('.collection-type-container');
        this.$entries = this.$container.find('.collection-type-entry');


        let prototype = this.$collectiontype.find('[data-prototype]').data('prototype');
        this.$container = this.$collectiontype.find('.collection-type-container');

        prototype = prototype.replace(/__name__/g, this.$entries.length);
        let $entry = $(prototype);

        this.$container.append($entry);

        this.$entries = $('.collection-type-container .collection-type-entry');

        return false;
    }

    /**
     * Remove
     */
    remove(e, $element)
    {
        e.preventDefault();

        let $entry = $element.closest('.collection-type-entry');

        $entry.remove();

        this.$entries = $('.collection-type-container .collection-type-entry');

        return false;
    }
}
