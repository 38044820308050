"use strict";

import { $, EventListener } from '@visualmedia/utils';

/**
 * @typedef {Object} MenuOptions
 * @property {String} className
 * @property {String} itemSelector
 */

/**
 * Frontend Menu
 *
 * @author Bertin van den Ham <b.vandenham@visualmedia.nl>
 *
 * @property {MenuOptions} _options
 */
export default class Menu
{
    /**
     * The default options of the frontend menu
     *
     * @returns {MenuOptions}
     */
    static get DEFAULTS()
    {
        return {
            className: "hover",
            itemSelector: "ul",
        };
    }

    /**
     * Creates a frontend menu
     *
     * @param {jQuery} $menu
     * @param {MenuOptions|Object|null} options
     */
    constructor($menu, options=null)
    {
        this._options   = $.extend({}, Menu.DEFAULTS, options || {});

        this._$menu  = $menu;
        this._$menuItems = $("li", this._$menu).has(this._options.itemSelector);

        this.setupListeners();
    }

    setupListeners()
    {
        this._$menuItems.on('mouseenter', EventListener(this, '_onMouseEnter'));
        this._$menuItems.on('mouseleave', EventListener(this, '_onMouseLeave'));
    }

    /**
     * OnMouseEnter Event Handler for a submenu element
     * @param e
     * @param $element
     * @private
     */
    _onMouseEnter(e, $element)
    {
        this.openSubmenu($element);
    }

    /**
     * OnMouseLeave Event Handler for a submenu element
     * @param e
     * @param $element
     * @private
     */
    _onMouseLeave(e, $element)
    {
        this.closeSubmenu($element);
    }

    /**
     * Open sub menu
     *
     * @param {jQuery} $element
     */
    openSubmenu($element)
    {
        $element.addClass(this.options.className);
    }

    /**
     * Close sub menu
     * @param {jQuery} $element
     */
    closeSubmenu($element)
    {
        $element.removeClass(this.options.className);
    }

    /**
     * get Element
     *
     * @returns {*}
     */
    get $menu()
    {
        return this._$menu;
    }

    get $items()
    {
        return this._$menuItems;
    }

    /**
     * get Options
     *
     * @returns {*}
     */
    get options()
    {
        return this._options;
    }
}

/**
 * Document Ready
 */
$(() => {
    global.menu = new Menu($(".menu"), {});
});