"use strict";

import $ from 'jquery';

/**
 * Document Ready
 */
$(() => {
    /**
     * Xhr Login
     */
    $(document).on('click', '.modal form button[type="submit"]', (event) => {
        var element = $(event.currentTarget);

        var form = element.closest('form');
        var action = form.attr('action');
        var data = form.serializeArray();

        $.post(action, data, (response) => {
            if (response.content != undefined) {
                var content = element.closest('.modal-content');
                element.closest('section').remove();
                content.append(response.content);
            }
            else if (response.redirect != undefined) {
                location.href = response.redirect;
            }
            else {
                location.reload();
            }
        });

        return false;
    })

    /**
     * Price Update
     */
    $(document).on('input', 'input[name="bidding[price]"], input[name="blind_bidding[price]"]', (event) => {
        var element = $(event.currentTarget);

        var form = element.closest('form');
        var action = '/price_update/';
        var data = {
            'slug': element.data('slug'),
            'price': element.val(),
        };

        function numberWithCommas(number) {
            let parts = number.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            return parts.join(',');
        }

        $.post(action, data, (response) => {            
            if (response.priceCalculator.transferTax > 0) {
                form.find('.transfer-tax').text(`€ ${ numberWithCommas(response.priceCalculator.transferTax) }`);
            }
            else {
                form.find('.transfer-tax').text('N.v.t.');
            }

            if (response.priceCalculator.transferTaxPercentage > 0) {
                form.find('.transfer-tax-percentage').text(`(${ response.priceCalculator.transferTaxPercentage }%)`);
            }
            else {
                form.find('.transfer-tax-percentage').text('');
            }

            form.find('.purchase-price-excl').text(`€ ${ numberWithCommas(response.priceCalculator.purchasePriceExcl) }`);
            form.find('.purchase-price-incl').text(`€ ${ numberWithCommas(response.priceCalculator.purchasePriceIncl) }`);
        });
    });
});
