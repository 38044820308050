"use strict";

import $ from 'jquery';

const IS_PHONE_REGEX = /i(Phone|Pod|Pad)|Android|BlackBerry|Mobile/i;

$(() => {
    if (new RegExp(IS_PHONE_REGEX).test(navigator.userAgent)) {
        for (let $tel of $('[data-tel]').toArray().map($)) {
            console.log($tel);
            $tel.attr('href', `tel:${$tel.data('tel')}`);
        }
    }
});