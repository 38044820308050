"use strict";

/**
 * Countdown
 */
$(() => {
    for (let $clock of $('.clock').toArray().map($)) {
        $clock.countdown($clock.data('countdown'))
            .on('update.countdown', function(event) {
              var format = '%H:%M:%S';
              if(event.offset.totalDays > 0) {
                format = '%-dd ' + format;
              }
              if(event.offset.weeks > 0) {
                format = '%-ww ' + format;
              }
              $(this).html(event.strftime(format));
            })
            .on('finish.countdown', function(event) {
              $(this).html('De veiling is gesloten!')
                .parent().addClass('disabled');
        });
    }
});
